html, body {
	font-size: 16px;
}

body {
	padding: 0;
	margin: 0;
	font-family: 'Metropolis', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.25;
}

* {
	box-sizing: border-box;
}

#root > main {
	display: block;
	min-height: var(--innerHeight, 100vh);
	overflow-x: hidden;
}

.container {
	margin: auto;
	padding: 0 2rem;
	max-width: 1440px;
}

.stacking-container {
	margin: auto;
	padding: 0 2rem;
	max-width: 480px;
}

@media screen and (min-width: 640px) {
	.stacking-container {
		max-width: 640px;
	}
}

@media screen and (min-width: 960px) {
	.stacking-container {
		max-width: 840px;
	}
}

@media screen and (min-width: 1200px) {
	.stacking-container {
		max-width: 960px;
	}
}

@media screen and (min-width: 1440px) {
	.stacking-container {
		max-width: 1200px;
	}
}

@media screen and (min-width: 1920px) {
	.stacking-container {
		max-width: 1440px;
	}
}
